<template>
  <div class="modal-card" style="background-color: #F6F6F6 !important;">
    <header class="modal-card-head" style="padding: 10px; background-color: #F6F6F6 !important;">
      <div class="isFlexGrow"><p class="modal-card-title">{{MindMap.title}}</p></div>
      <b-button @click="SaveMindMap" icon-left="save" v-if="canEdit">Save</b-button>
      <b-button @click="$parent.close()" icon-left="times" class="ml11">Close</b-button>
    </header>
    <section class="modal-card-body p0" style="position: relative; background-color: #fff !important;" id="modalbody">
      <b-loading :active.sync="isLoading" :can-cancel="false" :is-full-page="false"></b-loading>
      <div id="map" :style="mapStyle"></div>
    </section>
    <footer class="modal-card-foot isFlex" style="padding: 10px; background-color: #F6F6F6 !important; height: 42px;"> 
      <!-- <div class="isFlexGrow">
        <b-button @click="$parent.close()">Close</b-button>
      </div> -->
      <div v-if="canEdit && showButtons">
        <b-button @click="pressEdit" icon-left="edit" v-if="canEdit"></b-button>
        <b-button @click="meAddChild" icon-left="plus" icon-right="child" v-if="canEdit"></b-button>
        <b-button @click="meAddSib" icon-left="plus" v-if="canEdit">Sibling</b-button>
        <b-button @click="meMinus" icon-left="trash" v-if="canEdit"></b-button>
        <b-button @click="meUp" icon-left="arrow-alt-up" v-if="canEdit"></b-button>
        <b-button @click="meDown" icon-left="arrow-alt-down" v-if="canEdit"></b-button>
        <b-button @click="meMove" icon-left="truck" v-if="canEdit"></b-button>
        <b-button @click="meLine" icon-left="bezier-curve" v-if="canEdit"></b-button>
      </div>
      <div v-if="canEdit && showLinkButtons">
        <b-button @click="meLineDel" icon-left="trash" v-if="canEdit"></b-button>
        <b-button @click="showLinkButtons=false" icon-left="times" v-if="canEdit"></b-button>
      </div>
    </footer>
  </div>
</template>

<script>
import { Utils } from "@/mixins/utils.js";
import { Schemas } from "@/schemas/all.js";
import MindElixir, { E } from 'mind-elixir';
import debounce from "lodash/debounce";
import isEmpty from "lodash/isEmpty";
export default {
  name: "ModalMapForm",
  mixins: [Utils, Schemas],
  props: {
    data: Object,
  },
  data() {
    return {
      ModalWidth: `width: auto`,
			MindMap: { data: {} },
			isEdit: false,
			canEdit: false,
      canArc: false,
      isLoading: true,
      mapStyle: `position: relative; width: 100%; height: 400px;`,
      ME: null,
      MESeleted: '',
      MEFrom: '',
      MEFromLink: '',
      isShare: false,
      newData: {},
      activeNode: {},
      fromNode: {},
      showButtons: false,
      showLinkButtons: false,
    };
  },
  created() {
    const inh = document.body.clientHeight;
    this.mapStyle = `width: 100%; height: ${inh - 90}px;`;
    
  },
  mounted() {
    this.loadMindMap();
  },
  watch: {
    activeNode: {
      handler(val){
       this.SaveMindMap();
     },
     deep: true
    }
  },
  methods: {
		async loadMindMap() {
      const inid = this.data._id;
      if (localStorage.getItem("sub") === null) {
        this.isShare = true;
        this.MindMap = await this.DataGet(`/mindmapopen/${inid}`);
      } else {
        this.MindMap = await this.DataGet(`/mindmap/${inid}`);
      }
      this.canEdit = this.MindMap.canEdit;
      if (isEmpty(this.MindMap.data)) {
        this.MindMap.data = MindElixir.new(this.MindMap.title);
      };
      this.createMap();
    },
    
    createMap() {
      let enable = true;
      if (this.isShare) {
        enable = false;
      }
      this.ME = new MindElixir({
        el: '#map',
        direction: MindElixir.SIDE,
        // create new map data
        // data: MindElixir.new('new topic'),
        // or set as data that is return from `.getAllData()`
        data: this.MindMap.data,
        draggable: enable, // default true
        contextMenu: enable, // default true
        toolBar: enable, // default true
        nodeMenu: enable, // default true
        keypress: enable, // default true
      })
      this.ME.init();
      this.ME.bus.addListener('unselectNode', node => {
        this.showButtons = false;
      })
      this.ME.bus.addListener('disableEdit', node => {})
      this.ME.bus.addListener('selectLink', node => {
        console.log('selectLink', node);
        this.showLinkButtons = true;
        // document.querySelector('.map-container').style.overflow = 'hidden';
      })
      this.ME.bus.addListener('selectNode', node => {
        // node.topic = 'Selected';
        this.showButtons = true;
        this.activeNode = node;
        const inid = String(node.id);
        this.MESeleted = document.querySelector(`[data-nodeid="${inid}"]`);
        if (this.MEFrom !== '') {
          this.ME.moveNode(this.MEFrom, this.MESeleted);
          this.MEFrom = '';
        }
        if (this.MEFromLink !== '') {
          this.ME.createLink(this.MEFromLink, this.MESeleted);
          this.MEFromLink = '';
        }
        
      })
      setTimeout(() => {
        this.ME.initSide();
        this.isLoading = false;
      }, 500);
    },

    doSave: debounce(function() {
      this.SaveMindMap(false);
    }, 2000),

    async SaveMindMap() {
      const inmapdata = this.ME.getAllData();
      this.MindMap.data = inmapdata;
      if(this.canEdit) {
        this.DataSend('put', '/mindmap', this.MindMap, '', false);
      }
      // this.$emit("dataUpdated", { type, data: updata.rows });
      // if (close) {
      //   this.$parent.close();
      // }
    },
    meAddChild() {
      this.ME.addChild();
    },
    meAddSib() {
      this.ME.insertSibling()

    },
    meMinus() {
      this.ME.removeNode()
    },
    meLine() {
      // this.ME.removeNode()
      this.MEFromLink = this.MESeleted;
    },
    meLineDel () {
      // removeLink
      this.ME.removeLink();
      this.showLinkButtons = true;
    },
    meDown() {
      this.ME.moveDownNode()
      this.doSave();
    },
    meUp() {
      this.ME.moveUpNode()
      this.doSave();
    },
    meMove() {
      this.MEFrom = this.MESeleted;
      this.doSave();
      // this.ME.removeNode();
    },
    pressEdit() {
      this.ME.createInputDiv(this.MESeleted);
    }
  }
};
</script>

<style>
#map2 {
  height: 100%;
  width: 100%;
  overflow: auto;
}
.lt {
  font-size: 20px;
  left: 20px;
  top: 20px;
  width: 40px !important;
}
</style>
